import React from 'react';
import Any from './main';
import logo from './smspeedrunninglogo.png';
import Under from './yes/Under';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="topbar">
		    	<Link className="link" to="/">
		    	  <div className="topbaricon">
						  <img src={logo} className="icon" alt="logo" />
		  			  <h1 className="icontext">Scrap Mechanic Speedrunning</h1>
			      </div>
		      </Link>
          <div className="topbarbuttons">
            <Link className="link topbaricon" to="/anypercent">
              <h1 className="topbartext">Any%</h1>
            </Link>
            <Link className="link topbaricon" to="/UnderConstruction">
              <h1 className="topbartext">200spuds</h1>
            </Link>
            <Link className="link topbaricon" to="/UnderConstruction">
              <h1 className="topbartext">Farmbot%</h1>
            </Link>
          </div>
        </div>
        <div className="content">
          <Switch>
            <Route path="/anypercent">
              <Any />
            </Route>
            <Route path="/UnderConstruction">
              <div className="under">
                <div className="p1">
                  <div className="p2">
                    <h1 className="t1">Under Construction</h1>
                  </div>
                </div>
              </div>
            </Route>
         </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
