import React from 'react';
import List from './any.json';
import vidicon from './vid.png';
import {
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";

const Any = () => {
    let match = useRouteMatch();
    return (
      <div className="runs">
      
        <Switch>
          <Route path={`${match.path}/:runId`}>
            <OneAny />
          </Route>
          <Route path={match.path}>
            <div className="runlist">
              { List.map(run => {
                 return(
                   <Link to="/UnderConstruction" className="link">
                     {/* {`${match.url}/run=${run.id}`} */}
                    <div className="run" key={ run.id }>
                      <h1 className="link text">{ run.id }</h1>
                      <iframe max-width="1280" max-height="720" src={ run.embed} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      <h2 className="text">{ run.name }</h2>
                      <h3 className="text" >RTA Without Loads: { run.rtawl }</h3>
                      <h3 className="text" >RTA: { run.rta }</h3>
                      <h3 className="text">Game Version: { run.version }</h3>
                      <a className="link text" href={ run.url}>
                        <img className="icon" src={vidicon}></img>
                      </a>
                   </div>
                  </Link>
                )
              }) }
            </div>
          </Route>
        </Switch>
      </div>
    );
}

function OneAny() {
    let { modId } = useParams();
    const currentMod = List && List.find(mod => `mod=${mod.id}` === modId); 
    // console.log('cmod',currentMod, Modss, modId);
    return (
      <div>
        <div className="modpage">
          <div>
            <div className="moddownload">
              <h1 className="text">{ currentMod.name }</h1>
            </div>
            <h2 className="text">Images:</h2>
            <h2 className="text">Description:</h2>
            <h3 className="text">{ currentMod.description }</h3>
          </div>
          <div className="modinfo">
            <div className="userprofile">
              <Link to={ currentMod.owner.url } className="link">
                <img src={currentMod.ownerprofile} alt="" className="userimg"></img>
                <h2 className="text">Made By: { currentMod.owner.dname }</h2>
              </Link>
            </div>
            <h3 className="text">Published: { currentMod.published } </h3>
            <h3 className="text">Updated: { currentMod.updated }</h3>
            <h3 className="text">Game Version: { currentMod.version }</h3>
            <h3 className="text">Mod Version: { currentMod.version2 }</h3>
            <h3 className="text">Category: { currentMod.category }</h3>
            <h3 className="text">Rating: { currentMod.rateing }</h3>
          </div>
        </div>
      </div>
    )
  }
  
export default Any